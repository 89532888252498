@import url("https://use.typekit.net/vww0tze.css");
body {
    overflow-y: none;
    overflow-x: hidden;
    max-height:100%;
    font-family: 'Didact Gothic', 'Nunito', sans-serif !important;
    margin-left: auto;
    margin-right:auto;
}

div.center {
    margin-left: auto;
    margin-right: auto;
    display: table;
}

a {
    text-align:center;
    padding-right: 50px;
    font-weight:bold;
    font-size: 14px;
    color: white !important;
    padding-top:15px;
    text-decoration:none;
}

a:hover {
    text-decoration:none !important;
}

p {
    font-size: 1em;
}

.about {
    padding: 3em 0em;
}

.about-text {
    font-size: 1em;
    font-weight:bold;
    color:#58595b;
}

.about-title {
    color: #046797;
    font-weight:bold;
    text-align: left;
}

.article {
    margin-top:150px;
    height: 450px;
}

.article-content{
    font-weight:bold;
    color:#58595b;
    padding: 0em 1em;
}

.article-title {
    font-size:3vw !important;
    font-weight: 900 !important;
    color: #0f215b;
    padding: 1em .4em;
}

.awards {
    text-align:center;
}

.award-section {
    margin-top:150px;

}

.award-title {
    font-size:3vw !important;
    font-weight: 900 !important;
    color: #0f215b;
    margin-top: 40px;
}

.award-content {
    padding-left:50px;
}

.card-title-why {
    font-weight: bold;
    text-align:center;
}

.card-img-why {
    width:20%;
}

.center {
    text-align:center;
}

.close {
    color: #fff !important; 
    opacity: 1 !important;
}

.contact-link {
    text-align:center;
    padding: 10px 20px;
    font-weight:bold;
    font-size: 14px;
    color: white !important;
    text-decoration:none;
    cursor: pointer;
}


#email {
    text-align:center;
    background-color: #018bc2;
    height: 150px;
    width:100%;
    color: white;
}

.email-container {
    padding-top:55px;
}

.hyper-link {
    text-decoration:underline;
    font-size: 1.5em;
    font-weight:bold;
    cursor:pointer;
}

.footer {
    background-color:#f8f8f8;
    background-image: url("./assets/images/NomadFooter.png");
    background-size: cover;
    background-position: center center;
    margin: 0px;
    font-size: 1.5em;
    bottom:0;
    
}

.footer-links {
    padding-top:30px;
    text-align:left;
}

.footer-links-right {
    padding-top:20px;
    text-align:right;
}

.footer-link {
    color: white;
    font-weight: bold;
    font-size: 20px;
    padding-top:5px;
    cursor: pointer;
}

.footer-email-link {
    color: white;
    font-weight: bold;
    font-size: 14px;
    cursor: pointer;
}

.footer-link-button {
    color: white;
    font-weight: bold;
    font-size: 20px;
    background-color:transparent;
    border:none;
}

.footer-phone {
    color: #9dc141;
    font-size: 1em;
    font-weight: bold;
    cursor: pointer;
}

.footer-logo {
    padding-top:50px;
    text-align:center;
}

.footer-address {
    color: white;
    font-weight: bold;
    font-size: 16px;
    padding-top:5px;
}

.form-control:valid {
    background-color: transparent !important;
}

.header-title {
    color: #018bc2;
    font-size: 3.7em;
    font-weight: 800;
}

.header-banner {
    margin-top:120px;
    padding-top:50px;
    background-color:#f8f8f8;
    background-image: url("./assets/images/housing_header_1550x156.png");
    background-size: cover;
    background-position: center center;
    height:156px;
}

.home {
    padding-top:100px;
}

.housing-card {
    border:none;
}

.housing-card-img {
    padding-top:20px;
    width:100%;
}

.housing-card-content {
    padding-right:10px;
    font-weight:bold;
    color:#58595b;
    font-size:14px;
}

.housing-card-title {
    padding-top:40px;
    color: #046797;
    font-weight:bold;
}

.hosuing-image-mobile {
    height:150px;
}

.icon-image {
    width: 90px;
}

img  {
    width:100%;
}

.img-fluid {
    width:100%;
}

.line{
    border-bottom: 1px solid white;
}

.mobile-padding {
    padding-top: 6px;
    padding-right:35px;
}

.mobility{
    margin-top:4em;
    background-color:#f8f8f8;
    background-image: url("./assets/images/mobility_background.png");
    background-size: cover;
    background-position: center center;
    text-align:center;
}

.mobility-card {
    padding: 2em 1em;
}

.mobility-card ul {
    text-align:left;
    padding: .8em 4em;
}

.mobility-card ul li {
    font-weight:700;
    color: #046797;
    list-style: none; 
    font-size:1.4vw !important;
    line-height: 2em;
    list-style-position: inside;
    text-indent: -1.4em;
    margin-bottom: 1rem;
}

.mobility-card ul li::before {
    color: #9dc141;  
    content: "\2022"; 
    font-size: 1em; 
    padding-right: 1.1225em; 
    position: relative;
    top: 0em; 
}

.navbar {
    background-image: url("./assets/images/navigation_background.png");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.navbar-dark {
    color: white !important;
}

.nav-link {
    color: white;
    padding: 3px 10px; 
    display:inline-block;
    text-decoration: none;
}

.nav-link:active {
    color: white;
}

.nav-link:hover {
    color: white;
    text-decoration: none;
}

.navbar-toggler-icon{
    color: white !important;
}

.navbar-toggler:focus, .navbar-toggler:active {
    outline: none;
    box-shadow: none;
}

.nav-item {
    text-align:center;
    padding-right: 30px;
    font-weight:bold;
    font-size: 14px;
}

.navbar .navbar-brand {
    padding-left: 20px;
}

.no-padding {
    padding: 6px;
    padding-right:10px;
}

#news {
    margin-bottom:40px;
    text-align: center;
}

.news-card {
    text-decoration: none;
    background-color:#eaeaea;
    border-radius: 25px;
    padding: 1em 1em;
    margin: 1em;
}

.news-date {
    padding-left:20px;
    padding-right:20px;
    padding-top:10px;
    font-weight:bold;
    color:#58595b;
    text-align: left;
}

.news-title {
    padding-left:20px;
    color: #046797;
    font-weight:bold;
    text-align: left;
    font-size: 1.5em !important;
}

.news-content{
    padding-left:20px;
    padding-right:20px;
    font-weight:bold;
    color:#58595b;
    text-align: left;
    font-size: 1em !important;
}

.section-header {
    font-size:3vw !important;
    font-weight: 900 !important;
    color: #0f215b;
    margin: 40px;
    text-align:center;
}

.spacer {
    padding-top:50px;
}

.subheader-text{
    padding-top:50px;
    color: #8e8e8e;
    text-align: center;
}

#team {
    margin-top:20px;
    text-align:center;
    background-color:white;
}

.team-image {
    width: 150px;
    height:150px;
    border-radius: 50%;
}

.team-group {
    text-align:center;
    padding: 125px;
}

.team-container {
    margin-top:50px;
}

.testimonials {
    background-color:#f8f8f8;
    background-image: url("./assets/images/testimonials_background.png");
    background-size: cover;
    background-position: center center;
    text-align:center;
    height:350px;
}

.testimonial-header {
    font-size:3vw !important;
    font-weight: 900 !important;
    color: #0f215b;
    padding-top: 20px;
}

.testimonial-subheader {
    color: #018bc2;
    font-size: 1.6em;
    font-weight: 400;
    text-align:center;
    padding-top: 10px;
}

.testimonial-author {
    color: #b4b4b4;
    font-weight:bold;
}

.topbar {
    background-color: #018bc2;
    height: 44px;
}

.topbar {
    background-color: #018bc2;
    height: 44px;
    width:100%;
    padding-right:25px;
    color: white;
}

.topbar:after {
    clear:both;
}

.unbiased {
    margin-top:10px;
}

.unbiased ul {
    text-align:left;
    padding: .8em 1em;
}

.unbiased ul li {
    font-weight:700;
    color: #046797;
    list-style: none; 
    font-size:1.4vw !important;
    line-height: 2em;
    list-style-position: inside;
    text-indent: -1.4em;
}

.unbiased ul li::before {
    color: #9dc141;  
    content: "\2022"; 
    font-size: 1em; 
    padding-right: 1.1225em; 
    position: relative;
    top: 0em; 
}

.whynomad {
    margin-top:50px;
    background-color:white;
}

.whynomad ul {
    text-align:left;
    padding-inline-start:20px;
}

.whynomad ul li {
    font-weight:500;
    color: #046797;
    list-style: none; 
    font-size: 1.1em; 
    text-align:left;
    line-height: 1em;
    list-style-position: inside;
    text-indent: -1.4em;
}

.whynomad ul li::before {
    color: #9dc141;  
    content: "\2022"; 
    font-size: 1.1em; 
    padding-right: .4em; 
    position: relative;
    top: 0em; 
}

.about-link {
    color: #046797 !important;
}

@media only screen 
and (max-device-width : 1200px) { 
    .brand-logo {
        width:250px;
        padding-left: 10px;
    }
}

@media only screen 
and (max-device-width : 1024px) { 
    .footer {
        text-align:center;
        height: 400px;
    }

    .footer img {
        width:75%;
    }
}

@media only screen 
and (max-device-width : 768px) { 

    .about {
        padding: .1em 0em;
    }

    .about-text {
        font-size: 1.2em;
        padding: 0em 1em;
    }
    
    .about-title {
        padding: 0em .6em;
    }
    
    .brand-logo {
        width:250px;
    }

    .contact-link {
        padding: 10px 10px 10px 10px;
    }

    .footer {
        text-align:center;
        height: 350px;
    }

    .footer img {
        width:75%;
    }

    .header-title {
        font-size: 3.5em;
    }

    .housing-card-title {
        padding: .3em 1em;
    }

    .housing-card-content {
        padding: .3em 1.2em;
        font-size: 1.3em;
    }

    .mobility {
        background-color:#f8f8f8;
        background-image: none;
    }

    .mobility-card {
        padding: 1em 1em;
    }

    .mobility-card ul {
        text-align:left;
        padding: .8em 2em;
    }

    .mobility-card ul {
        padding: .4em 1em;
    }

    .mobility ul li {
        font-size:2.5vw !important;
    }

    .navbar .navbar-brand {
        padding-left: .5em;
    }

    .section-header  {
        font-size:7vw !important;
    }

    .subheader-text{
        padding-top:10px;
    }

    .topbar {
        padding-right:15px;

    }

    .unbiased ul {
        padding: 1em 2.5em;
    }

    .unbiased ul li {
        font-size:2.5vw !important;
    }
}


@media only screen 
and (max-device-width : 375px) { 
    /* STYLES GO HERE */

    .about-text {
        font-size: 1.2em;
        padding: 0em 1em;
    }
    
    .about-title {
        padding: 0em .6em;
    }

    .contact-image {
        width:50%;
    }

    .header-title {
        font-size: 2.0em;
    }

    .header-banner {
        margin-top:100px;
        padding-top:20px;
        height:100px;
    }

    .housing-card-title {
        padding: .3em .5em;
    }

    .housing-card-content {
        padding: .3em .8em;
        font-size: 1em;
    }

    .hyper-link {
        font-size: 1em;
    }

    .icon-image {
        width: 150px;
    }

    .mobility {
        background-color:#f8f8f8;
        background-image: none;
    }

    .mobility-card {
        padding: 1em 0em !important;
    }

    .mobility-card ul {
        padding: .4em 1em;
    }

    .mobility ul li {
        font-size:4.5vw !important;
    }

    .navbar  .brand-logo {
        width:200px;
    }

    .navbar .navbar-brand {
        padding-left: 0em;
    }

    .section-header  {
        font-size:7vw !important;
    }

    .subheader-text {
        padding-top:10px;
        font-size: 1.6em;
    }

    .testimonial-header {
        font-size: 1.1em;
    }

    .testimonial-subheader {
        font-size:7vw !important;
    }

    .unbiased ul {
        padding: .8em 1em;
    }

    .unbiased ul li {
        font-size:4.5vw !important;
    }
}

@media only screen 
and (max-device-width : 414px) { 
    /* STYLES GO HERE */
    .about-text {
        font-size: 1.2em;
        padding: 0em 1em;
    }
    
    .about-title {
        padding: 0em .6em;
    }

    .contact-image {
        width:50%;
    }

    .header-title {
        font-size: 2.0em;
    }

    .header-banner {
        margin-top:100px;
        padding-top:20px;
        height:100px;
    }

    .housing-card-title {
        padding: .3em .5em;
    }

    .housing-card-content {
        padding: .3em .8em;
        font-size: 1em;
    }

    .hyper-link {
        font-size: 1em;
    }

    .icon-image {
        width: 150px;
    }

    .mobility {
        background-color:#f8f8f8;
        background-image: none;
    }

    .mobility-card {
        padding: 1em 0em !important;
    }

    .mobility-card ul {
        padding: .4em 1em;
    }

    .mobility ul li {
        font-size:4.5vw !important;
    }

    .navbar  .brand-logo {
        width:200px;
    }

    .navbar .navbar-brand {
        padding-left: 0em;
    }

    .section-header  {
        font-size:7vw !important;
    }

    .subheader-text {
        padding-top:10px;
        font-size: 1.6em;
    }

    .testimonial-header {
        font-size: 1.1em;
    }

    .testimonial-subheader {
        font-size:7vw !important;
    }

    .unbiased ul {
        padding: .8em 1em;
    }

    .unbiased ul li {
        font-size:4.5vw !important;
    }
}

@media only screen 
and (max-device-width : 411px) { 
    /* STYLES GO HERE */
    .brand-logo {
        width:200px;
    }
    .contact-image {
        width:50%;
    }

    .header-title {
        font-size: 2.0em;
    }

    .header-banner {
        margin-top:100px;
        padding-top:20px;
        height:100px;
    }

    .navbar-brand {
        padding-left: 10px;
    }

    .section-header  {
        font-size:7vw !important;
    }
}

@media only screen 
and (max-device-width : 360px) { 
    /* STYLES GO HERE */
    .brand-logo {
        width:180px;
    }
    .contact-image {
        width:50%;
    }

    .header-title {
        font-size: 2em;
    }

    .header-banner {
        margin-top:100px;
        padding-top:20px;
        height:100px;
    }

    .navbar-brand {
        padding-left: 1px;
    }

    .section-header  {
        font-size:7vw !important;
    }
}

@media only screen 
and (max-device-width : 320px) { 
    /* STYLES GO HERE */
    .navbar  .brand-logo {
        width:150px;
    }

    .navbar .navbar-brand {
        padding-left: 0em;
    } 
    .contact-image {
        width:50%;
    }

    .header-title {
        font-size: 2em;
    }

    .header-banner {
        margin-top:100px;
        padding-top:20px;
        height:100px;
    }

    .navbar-brand {
        padding-left: 1px;
    }

    .section-header  {
        font-size:7vw !important;
    }
}
